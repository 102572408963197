var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.PREFIX + '-modal',"hide-footer":"","size":"lg"},on:{"hide":function($event){return _vm.resetForm()},"shown":function($event){return _vm.initData()}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('label_allowed_users'))+" ")]},proxy:true},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('validation-observer',{ref:_vm.PREFIX + '_FORM',staticClass:"p-1",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":_vm.$t('label_allowed_users'),"rules":"","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(validationProps){return [_c('b-form-group',{attrs:{"label":_vm.$t('label_allowed_users')}},[_c('infinity-scroll',{attrs:{"selected-prop":"id","multiple":true,"url":"/user","placeholder":_vm.$t('label_select'),"close-on-select":false,"default-selection":_vm.chosenUsers},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}],null,true),model:{value:(_vm.itemData[_vm.adding_user_type]),callback:function ($$v) {_vm.$set(_vm.itemData, _vm.adding_user_type, $$v)},expression:"itemData[adding_user_type]"}}),_c('b-form-invalid-feedback',{attrs:{"state":validationProps.failedRules.length > 0}},[_vm._v(" "+_vm._s(validationProps.errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('button',{staticClass:" btn btn-default",on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.hide(_vm.modalRef? _vm.modalRef : _vm.PREFIX + '-modal')}}},[_vm._v(" "+_vm._s(_vm.$t('label_close'))+" ")]),_c('button',{staticClass:"btn btn-primary waves-effect waves-light",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit);_vm.focusOnFormError(invalid)}}},[_vm._v(_vm._s((_vm.action == 'editing')?_vm.$t('label_edit'):_vm.$t('label_submit'))+" ")])])]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }