<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_menu_role_access')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-header class="pb-50">
                <h5>{{ $t('label_menu_role_access') }}</h5>
            </b-card-header>

            <b-card-body>
                <b-tabs content-class="mt-1" >

                    <b-tab v-for="role_item, key in roleItems"
                           :key="key"
                           :ref="role_item.role + '_TAB'"
                           @click="curPageLabel = $t('label_' + role_item.role); activeTab = role_item.role"
                           :title="$t('label_' + role_item.role)"
                           lazy
                    >

                        <tab-role
                                @choseAllowedUsers="choseAllowedUsers"
                                @refreshData="getPageData()"
                                :perm-names="permNames"
                                :permissions="role_item.access"
                                :role="role_item.role"
                                :ref="role_item.role + '_CONTENT_TAB'"
                                :key="key + (new Date())"
                                :auth_user="auth_user"
                        ></tab-role>

                        <!--@edit-item="editedTask = $event;"-->
                        <!--@add-item="editedTask = false;"-->

                    </b-tab>


                </b-tabs>
            </b-card-body>
        </b-card>

        <allowed-users-modal :processedrole="processedRole" :adding_user_type="adding_user_type" ></allowed-users-modal>

    </div>
</template>

<script>

    import {
         BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar,

    } from 'bootstrap-vue'

    import tabRole from './tabRole'
    import allowedUsersModal from './../../includes/modals/allowedUsersModal'

    import {MODULE_PREFIX as PREFIX, MODULE_PREFIX} from './moduleHelper'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            tabRole,
            allowedUsersModal

        },
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                activeTab: 'agent_TAB',
                curPageLabel: 'label_tasks',

                roleItems: [],
                permNames:[],
                auth_user: this.authUser(),
                processedRole:'',
                adding_user_type:'contact_related_users',
            }
        },

        created() {

          this.getPageData();


        },
        methods:{
            getPageData(){
                this.async('get', '/' + MODULE_PREFIX , {params:{}}, function(res){
                    this.roleItems = res.data.items;
                    this.permNames = res.data.controllers;
                });
            },
            choseAllowedUsers(e){
                this.$bvModal.show('allowed-users-modal');
            }
        }


    }
</script>

